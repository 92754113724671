.fr-popup {
  z-index: 9999 !important;
}

.fr-view * {
  font-family: inherit;
}

.fr-box {
  height: 100% !important;
}

.fr-box.fr-basic {
  border-radius: 0 !important;
}

.fr-toolbar,
.fr-second-toolbar,
.fr-box.fr-basic .fr-wrapper {
  border-radius: 0 !important;
  border: none !important;
}

.fr-view {
  width: 100%;
  height: 100%;
}

.fr-view img.fr-dii {
  margin: 0;
  max-width: unset;
  overflow-clip-margin: unset;
}

.fr-view img {
  overflow-clip-margin: unset;
}

.fr-view img.fr-dib {
  margin: 0 auto;
}


/* custom */
.fr-border-none > tbody > tr td {
  border: none;
}

.fr-view img.fullpage {
  width: 100vw !important;
  height: 100vh !important;
  object-fit: cover;
  object-position: center;
  /* margin-left: calc(-50vw + 50%); */
}

.fr-view .temp-dash {
  border: 1px dashed #eeeeee;
  padding: 20px;
}

.fr-view .row {
  display: flex;
  width: 100%;
}

.fr-view .image-with-center {
  position: relative;
}

.fr-view .image-with-center > *:not(img) {
  position: absolute;
}

@media (hover: hover) and (pointer: fine) {
  .fr-view .scale-up {
    transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    transform: scale(1);
  }

  .fr-view span.scale-up {
    display: inline-block;
  }
  .fr-view:hover .scale-up {
    transform: scale(1.15);
  }
}


@media only screen and (max-width: 1280px) {
  .fr-view * {
    max-width: 100% !important;
  }
}