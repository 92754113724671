/* .swiper-wrapper {
  transition-duration: 3000ms;
  transition-timing-function: linear;
} */

.alice-carousel__prev-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 32px;
  height: 32px;
  padding: 0;
}

.alice-carousel__prev-btn-wrapper {
  width: 100%;
  height: 100%;
}

.alice-carousel__prev-btn-item {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/icon/slide-arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
}
.alice-carousel__prev-btn-item > span {
  display: none;
}

.alice-carousel__next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  width: 32px;
  height: 32px;
  padding: 0;
}

.alice-carousel__next-btn-wrapper {
  width: 100%;
  height: 100%;
}

.alice-carousel__next-btn-item {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/icon/slide-arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(-180deg);
  padding: 0;
}

.board-gallery .alice-carousel__prev-btn-item.__inactive,
.board-gallery .alice-carousel__next-btn-item.__inactive {
  display: none;
}

.alice-carousel__next-btn-item > span {
  display: none;
}

.alice-carousel__slide-info {
  background-color: rgba(0, 0, 0, 0.1) !important;
  padding: 8px 14px !important;
  font-size: 12px;
  line-height: 1.5;
}
